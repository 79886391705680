.home {
    $blue: #0d6efd;

    .carousel-item {
        min-height: 304px !important;
        height: 304px !important;
    }

    .about-card {
        background-color: #cfe2ff !important;
    }

    .products-gallery-body {
        background-color: #cfe2ff !important;
    }

    .card-title {
        font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    }

    .carousel-second {
        color: black;
    }

    .shop-card-title {
        text-align: center;
    }

    .delivery-time {
        text-align: right;
    }

    .products-gallery-title {
        text-align: center;
    }
}