.products-carousel {
    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E");
    }

    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
    }

    .col-3 img {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    @media only screen and (min-width: 576px) {
        .add-margin-on-bigger {
            margin-left: 80px;
            margin-right: 80px;
        }
    }

    @media only screen and (max-width: 575px) {
        .carousel-control-prev-icon {
            position: relative;
            left: -40px;
        }

        .carousel-control-next-icon {
            position: relative;
            left: 40px;
        }
    }
}